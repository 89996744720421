import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch';
import { debounce } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { Loader, SecondaryHeader } from '@/components';
import { useHelpDocsService } from '@/store/services/helpDocs.service';
import { useHelpSectionsService } from '@/store/services/helpSections.service';
import { RootState } from '@/store/slices';
import {
	ALGOLIA_API_KEY,
	ALGOLIA_APP_ID,
	ALGOLIA_HELP_DOCS_INDEX,
} from '@/utils/constants';
import { isSuperAdmin } from '@/utils/helpers';
import { HelpDocType, HelpSectionType } from '@/utils/types';

const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const helpDocIndex = algoliaClient.initIndex(ALGOLIA_HELP_DOCS_INDEX);

const styles = {
	loaderContainer: {
		height: 'calc(100vh - 220px)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	helpDocLink: {
		color: '#585858',
		cursor: 'pointer',
		textDecoration: 'underline',
		fontSize: '20px',
		lineHeight: '40px',
	},
};

const HelpDocs: FC = () => {
	const router = useRouter();
	const { data: user } = useSelector((state: RootState) => state.me);

	const [helpDocs, setHelpDocs] = useState<HelpDocType[]>([]);
	const [isLoadingSearchHelpDocs, setIsLoadingSearchHelpDocs] = useState(false);
	const [searchText, setSearchText] = useState<string>('');
	const [sections, setSections] = useState<HelpSectionType[]>([]);

	const { getHelpSections } = useHelpSectionsService();
	const { getHelpDocs } = useHelpDocsService();

	const getSection = useCallback(() => {
		const { section } = router.query;
		return section ? sections.find((s) => s.slug === section) : null;
	}, [router.query, sections]);

	const getHelpDocsCallback = useCallback(async () => {
		const section = getSection();
		const result = await getHelpDocs(section ? { helpSectionId: section.id } : {});
		setHelpDocs(result);
	}, [getHelpDocs, getSection]);

	const getHelpSectionsCallback = useCallback(async () => {
		setSections(await getHelpSections());
	}, [getHelpSections]);

	const searchHelpDocsCallback = useCallback(
		async (text: string) => {
			setIsLoadingSearchHelpDocs(true);
			const { hits } = await helpDocIndex.search(text, {
				facets: ['*'],
				facetFilters: isSuperAdmin(user!) ? [] : ['isDraft:false'],
			});
			setHelpDocs(hits as unknown as HelpDocType[]);
			setIsLoadingSearchHelpDocs(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const debouncedSearchHelpDocsCallback = useMemo(
		() => debounce(searchHelpDocsCallback, 500),
		[searchHelpDocsCallback],
	);

	const onHelpDocClick = (helpDocId: string) => {
		router.push(`help-docs/${helpDocId}`);
	};

	useEffect(() => {
		if (!sections.length) return;
		if (searchText) {
			debouncedSearchHelpDocsCallback(searchText);
		} else {
			getHelpDocsCallback();
		}
		return () => {
			debouncedSearchHelpDocsCallback.cancel();
		};
	}, [getHelpDocsCallback, sections, searchText, debouncedSearchHelpDocsCallback]);

	useEffect(() => {
		getHelpSectionsCallback();
	}, [getHelpSectionsCallback]);

	if (!helpDocs.length) {
		return <Loader />;
	}

	return (
		<Box height="100%">
			<SecondaryHeader
				title={getSection()?.name || 'Getting Started'}
				showPrimaryBtn={false}
				showSearch={true}
				searchPlaceholder="Search help docs..."
				searchText={searchText}
				handleChangeSearch={(value: string) => setSearchText(value)}
			/>
			<Box height="calc(100% - 78px)">
				<Grid px={6} py={4}>
					{isLoadingSearchHelpDocs ? (
						<Box sx={styles.loaderContainer}>
							<Loader backdrop={false} />
						</Box>
					) : (
						<Box textAlign="left">
							{helpDocs.map((helpDoc) => (
								<Typography
									key={`help-doc-${helpDoc.id}`}
									style={styles.helpDocLink}
									onClick={() => onHelpDocClick(helpDoc.id)}
								>
									{helpDoc.title}
								</Typography>
							))}
						</Box>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

export default HelpDocs;
